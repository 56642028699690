import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';

import classNames from 'classnames';
import ReactPaginate from 'react-paginate';

import SubmitButton from '@/SubmitButton';
import { getCSRFToken } from '@/utilities';

import NewUsersList from './NewUsersList';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useDebouncedStateWithCallback from '@shared/hooks/useDebouncedStateWithCallback';
import TextInput from '@shared/ui/Inputs/TextInput';
import type { NewUser } from '@models/User';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  psaName: string;
  count: number;
  currentPage: number;
  loading: boolean;
  totalPages: number;
  users: NewUser[];
  redirectPath?: string | null;
  onPageChange: (page: number) => void;
  onTermChange: (term: string) => void;
}

const NewUsers: FC<Props> = ({
  psaName,
  currentPage,
  totalPages,
  users,
  loading,
  redirectPath,
  onPageChange,
  onTermChange,
}) => {
  const [invitedUsers, setInvitedUsers] = useState<NewUser[]>([]);
  const [visibleUsers, setVisibleUsers] = useStateFromProp(users);
  const [displayTerm, setDisplayTerm] = useState('');
  const [term, setTerm] = useDebouncedStateWithCallback('', 500);

  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTerm = event.target.value;
    setDisplayTerm(newTerm);
    setTerm(newTerm);
  };

  const handlePageChange = (newPage: { selected: number }): void => {
    onPageChange(newPage.selected + 1);
  };

  const handleUserInvite = (user: NewUser): void => {
    setInvitedUsers([...invitedUsers, user]);
  };

  const handleUserCancel = (user: NewUser): void => {
    setInvitedUsers(invitedUsers.filter(u => u.id !== user.id));
  };

  const handleUserChange = (user: NewUser): void => {
    setVisibleUsers(visibleUsers.map(u => (u.id === user.id ? user : u)));
  };

  useEffect(() => {
    onTermChange(term);
  }, [term, onTermChange]);

  return (
    <div>
      <div className="card mb-3">
        <div className="card-header">Users to add to TimeZest</div>
        <div className="card-body">
          <div>
            {invitedUsers.length > 0 ? (
              <>
                <NewUsersList users={invitedUsers} onRemove={handleUserCancel} />
                <form action="/settings/users/invitations" method="POST">
                  {redirectPath && <input type="hidden" name="redirect_path" value={redirectPath} />}
                  <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
                  {invitedUsers.map(user => (
                    <Fragment key={user.id}>
                      <input name="invitations[][email]" type="hidden" value={user.email} />
                      <input name="invitations[][name]" type="hidden" value={user.name} />
                      <input name="invitations[][role]" type="hidden" value={user.role} />
                    </Fragment>
                  ))}
                  <SubmitButton
                    disabled={!invitedUsers.length}
                    className={classNames('btn', 'w-100', {
                      'btn-primary': invitedUsers.length,
                      'btn-secondary': !invitedUsers.length,
                    })}
                  >
                    Invite these users to TimeZest
                  </SubmitButton>
                </form>
              </>
            ) : (
              <h5 className="mb-0 text-center">No users have been selected to be added to TimeZest.</h5>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Select the users from {psaName} you wish to add to TimeZest</div>
        <div className="card-body">
          <form className="d-flex align-items-center mb-3 ms-2">
            <label htmlFor="search-term">Only show users whose name contains:</label>
            <div className="flex-grow-1 ms-2">
              <TextInput
                id="search-term"
                name="search-term"
                className="w-100"
                value={displayTerm}
                onChange={handleTermChange}
              />
            </div>
          </form>
          {loading ? (
            <div>
              <FontAwesomeIcon spin icon="circle-notch" />
              &nbsp;&nbsp; Loading users from {psaName}...
            </div>
          ) : (
            <NewUsersList
              users={visibleUsers}
              disabledUsers={invitedUsers}
              onAdd={handleUserInvite}
              onChange={handleUserChange}
            />
          )}
          {totalPages > 1 && (
            <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              containerClassName="pagination justify-content-center mb-0 mt-3"
              disableInitialCallback={true}
              forcePage={currentPage - 1}
              breakClassName="page-item"
              breakLabel={<span className="page-link">...</span>}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewUsers;
