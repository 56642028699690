import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const SkipAllActionsForClosedTicket: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      icon="task-times"
      summary="Skip all actions in this trigger when the associated ticket is closed"
      readOnly={readOnly}
    >
      <p>
        Check if associated ticket in HaloPSA is closed, and skip further actions in this trigger if it has been closed.
      </p>
    </Action>
  </ErrorBoundary>
);

export default SkipAllActionsForClosedTicket;
