import type { FetchResult } from '@apollo/client';
import type {
  Action,
  CreateCreateCompanyTodoForAutotaskMutation,
  CreateCreateContactForAutotaskMutation,
  CreateCreateNoteOnTicketForAutotaskMutation,
  CreateCreateServiceCallForAutotaskMutation,
  CreateCreateServiceTicketForAutotaskMutation,
  CreateCreateActivityForConnectWiseMutation,
  CreateCreateContactForConnectWiseMutation,
  CreateCreateNoteOnTicketForConnectWiseMutation,
  CreateCreateScheduleEntryForConnectWiseMutation,
  CreateCreateServiceTicketForConnectWiseMutation,
  CreateCreateEventForOffice365Mutation,
  CreateMoveTicketToBoardAndStatusForConnectWiseMutation,
  CreateUpdateTicketStatusForAutotaskMutation,
  CreateUpdateTicketToPresetStatusForAutotaskMutation,
  CreateUpdateTicketToPresetStatusForHaloPsaMutation,
  CreateUpdateTicketStatusForConnectWiseMutation,
  DefaultAction,
} from '@graphql/generated';

export function actionIsCreateable(type: string): boolean {
  return (
    [
      'autotask/skip_all_actions_for_closed_ticket',
      'autotask/update_contact_phone_number',
      'autotask/update_ticket_primary_resource',
      'connect_wise/skip_all_actions_for_closed_ticket',
      'connect_wise/update_contact_phone_number',
      'connect_wise/update_ticket_owner',
      'connect_wise/update_ticket_contact_phone_number',
      'halo_psa/skip_all_actions_for_closed_ticket',
      'halo_psa/update_user_phone_number',
      'halo_psa/update_ticket_agent',
      'service_now/update_task_owner',
      'office365/delete_event',
      'office365/update_event',
      'service_now/update_task_owner',
      'teams_meetings/create_meeting',
      'teams/delete_meeting',
      'time_zest/close_scheduling_request',
      'time_zest/delete_appointments',
      'time_zest/update_appointments',
      'zoom/create_meeting',
      'zoom/delete_meeting',
      'zoom/update_meeting',
    ].indexOf(type) >= 0
  );
}

type CreateMutation =
  | CreateCreateCompanyTodoForAutotaskMutation
  | CreateCreateContactForAutotaskMutation
  | CreateCreateNoteOnTicketForAutotaskMutation
  | CreateCreateServiceCallForAutotaskMutation
  | CreateCreateServiceTicketForAutotaskMutation
  | CreateCreateActivityForConnectWiseMutation
  | CreateCreateContactForConnectWiseMutation
  | CreateCreateNoteOnTicketForConnectWiseMutation
  | CreateCreateScheduleEntryForConnectWiseMutation
  | CreateCreateServiceTicketForConnectWiseMutation
  | CreateCreateEventForOffice365Mutation
  | CreateMoveTicketToBoardAndStatusForConnectWiseMutation
  | CreateUpdateTicketStatusForAutotaskMutation
  | CreateUpdateTicketToPresetStatusForHaloPsaMutation
  | CreateUpdateTicketToPresetStatusForAutotaskMutation
  | CreateUpdateTicketStatusForConnectWiseMutation;

export function updateActionWithResult(action: Action, result: FetchResult<CreateMutation>): void {
  if (!result?.data?.createAction.action) return;

  action.id = (result.data.createAction.action as DefaultAction).id;
}
