import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns/format';
import { endOfDay } from 'date-fns/endOfDay';
import { startOfDay } from 'date-fns/startOfDay';
import { hoursToMinutes } from 'date-fns/hoursToMinutes';
import { millisecondsToMinutes } from 'date-fns/millisecondsToMinutes';
import times from 'lodash/times';

import Time from '@shared/time';

export class DateTime extends Time {
  private date: Date;
  private timezone: string | undefined;

  constructor(timezone?: string, initialDateValue?: string | Date) {
    let date: Date;

    if (timezone) {
      date = initialDateValue ? toZonedTime(initialDateValue, timezone) : toZonedTime(new Date(), timezone);
    } else {
      date = initialDateValue ? new Date(initialDateValue) : new Date();
    }

    super({ date });
    this.date = date;
    this.timezone = timezone;
  }

  static today(currentDate: string, timezone: string): boolean {
    return currentDate === formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');
  }

  static getMinuteValueFromTime(timeString: string, timezone: string): number {
    if (!timeString) return 0;

    const hour = formatInTimeZone(new Date(timeString), timezone, 'HH');
    const minute = formatInTimeZone(new Date(timeString), timezone, 'mm');

    return hoursToMinutes(Number(hour)) + Number(minute);
  }

  static durationInMinutes(endTime: string, startTime: string): number {
    return millisecondsToMinutes(new Date(endTime).getTime() - new Date(startTime).getTime());
  }

  gt(date: DateTime): boolean {
    return this.date > date.date;
  }

  gte(date: DateTime): boolean {
    return this.date >= date.date;
  }

  lt(date: DateTime): boolean {
    return this.date < date.date;
  }

  lte(date: DateTime): boolean {
    return this.date <= date.date;
  }

  navigateWeeks(direction: 'previous' | 'next'): string {
    const targetDate = this.date;

    const adjustment = direction === 'next' ? 7 : -7;

    targetDate.setDate(targetDate.getDate() + adjustment);

    return format(targetDate, 'yyyy-MM-dd');
  }

  navigateDays(direction: 'previous' | 'next'): string {
    const targetDate = this.date;

    const adjustment = direction === 'next' ? 1 : -1;

    targetDate.setDate(targetDate.getDate() + adjustment);

    return format(targetDate, 'yyyy-MM-dd');
  }

  day() {
    return format(this.date, 'MMMM d');
  }

  dayNameInWeek() {
    return format(this.date, 'EEEE');
  }

  dayNumber() {
    return format(this.date, 'dd');
  }

  weekNumber() {
    return format(this.date, 'w');
  }

  month() {
    return format(this.date, 'MMMM');
  }

  formattedDateTime() {
    return `${this.formattedDate()} ${this.shortTime()}`;
  }

  formattedDate() {
    return `${this.dayNumber()} ${this.month()} ${this.year()}`;
  }

  shortMonth() {
    return format(this.date, 'M');
  }

  year() {
    return format(this.date, 'yyyy');
  }

  shortTime() {
    return format(this.date, 'h:mma').toLowerCase();
  }

  hours() {
    return format(this.date, 'HH');
  }

  minutes() {
    return format(this.date, 'mm');
  }

  format(formatString: string) {
    return format(this.date, formatString);
  }

  getFullDate() {
    return this.date;
  }

  getStartOfDay(): DateTime {
    return new DateTime(this.timezone, startOfDay(this.date));
  }

  getEndOfDay() {
    return endOfDay(this.date);
  }

  getWeekDays(): DateTime[] {
    const day = this.date.getDay();

    const firstDayOfTheWeek = this.date;
    firstDayOfTheWeek.setDate(this.date.getDate() - day);

    const dateTimeObjects: DateTime[] = times(7, i => {
      const day = new Date(firstDayOfTheWeek);

      return new DateTime(this.timezone, day).incrementDay(i);
    });

    return dateTimeObjects;
  }

  incrementDay(increment: number): DateTime {
    const newDate = new Date(this.date);
    newDate.setDate(this.date.getDate() + increment);
    return new DateTime(this.timezone, newDate);
  }

  toLocalISOString() {
    return format(this.date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
  }

  toLocalISODate() {
    return format(this.date, 'yyyy-MM-dd');
  }
}
