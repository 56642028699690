import type { FC } from 'react';
import { useContext, useState } from 'react';

import classNames from 'classnames';

import { DirtyContext } from '@/DirtyContext';

import type { DurationUnit } from '@models/AppointmentType';
import InputGroup from '@shared/ui/Inputs/InputGroup';
import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';

type Unit = 'minutes' | 'hours' | 'days' | 'business_minutes' | 'business_hours' | 'business_days';

interface Props {
  nameDuration: string;
  nameUnit: string;
  initialDuration: number;
  initialUnit: DurationUnit;
  error: string | null;
}

const DurationWithBusinessHours: FC<Props> = ({ nameDuration, nameUnit, initialDuration, initialUnit, error }) => {
  const [unit, setUnit] = useState(detectUnit(initialDuration, initialUnit));
  const { handleDirty } = useContext(DirtyContext);
  const [dropdownShowing, setDropdownShowing] = useState(false);
  const [duration, setDuration] = useState(initialDuration);
  const [durationStr, setDurationStr] = useState(convert(duration, unit.underlyingUnit, unit.displayUnit).toString());

  const unitsDropdownMenu = useRefWithClickOutside<HTMLDivElement>(() => setDropdownShowing(false));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const valueStr = e.target.value;

    if (handleDirty) {
      handleDirty();
    }

    if (Number(e.target.value) < 0) {
      setDuration(0);
      setDurationStr('');
      return;
    }

    setDuration(convert(Number(e.target.value), unit.displayUnit, unit.underlyingUnit));
    setDurationStr(valueStr);
  };

  const handleDropdownClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setDropdownShowing(!dropdownShowing);
  };

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!e.key.match(/\d/)) e.preventDefault();
  };

  const handleUnitSelection =
    (newUnit: Unit) =>
    (e: React.MouseEvent): void => {
      e.preventDefault();

      if (handleDirty) {
        handleDirty();
      }

      const underlyingUnit = getUnderlyingUnit(newUnit);

      setDropdownShowing(false);
      setDuration(convert(Number(durationStr), newUnit, underlyingUnit));
      setUnit({ underlyingUnit, displayUnit: newUnit });
    };

  return (
    <>
      <InputGroup
        type="number"
        name={`${nameDuration}-input`}
        aria-label="Text input with dropdown button"
        value={durationStr}
        style={{ width: '100px' }}
        error={error || undefined}
        append={
          <div className="position-relative">
            <button
              className={classNames('btn', 'dropdown-toggle', {
                'btn-outline-danger': error,
                'btn-outline-form': !error,
              })}
              type="button"
              aria-haspopup="true"
              aria-expanded={!dropdownShowing}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={handleDropdownClick}
            >
              {unit.displayUnit.replace('_', ' ')}
            </button>
            <div
              ref={unitsDropdownMenu}
              className="dropdown-menu"
              style={{ display: dropdownShowing ? 'block' : 'none', right: 0, left: 'auto', minWidth: 'auto' }}
            >
              <button className="dropdown-item" type="button" onClick={handleUnitSelection('minutes')}>
                minutes
              </button>
              <button className="dropdown-item" type="button" onClick={handleUnitSelection('hours')}>
                hours
              </button>
              <button className="dropdown-item" type="button" onClick={handleUnitSelection('days')}>
                days
              </button>
              <button className="dropdown-item" type="button" onClick={handleUnitSelection('business_hours')}>
                business hours
              </button>
              <button className="dropdown-item" type="button" onClick={handleUnitSelection('business_days')}>
                business days
              </button>
            </div>
          </div>
        }
        onChange={handleChange}
        onKeyPress={handleKeyPressed}
      />
      <input type="hidden" name={nameDuration} value={duration} />
      <input type="hidden" name={nameUnit} value={unit.underlyingUnit} />
    </>
  );
};

export default DurationWithBusinessHours;

function getUnderlyingUnit(unit: Unit): DurationUnit {
  switch (unit) {
    case 'business_days':
      return 'business_days';
    case 'business_hours':
      return 'business_minutes';
    default:
      return 'minutes';
  }
}

function detectUnit(value: number, underlyingUnit: DurationUnit): { underlyingUnit: DurationUnit; displayUnit: Unit } {
  let displayUnit: Unit = 'minutes';

  if (underlyingUnit === 'business_minutes') displayUnit = 'business_hours';
  else if (underlyingUnit === 'business_days') displayUnit = 'business_days';
  else {
    if (value > 60 && value < 1440 && isValidForRoundUp(value, 60)) {
      displayUnit = 'hours';
    } else if (value >= 1440 && isValidForRoundUp(value, 1440)) {
      displayUnit = 'days';
    }
  }

  return { underlyingUnit, displayUnit };
}

const UNITS = {
  minutes: { minutes: 1, hours: 1 / 60, days: 1 / 1440 },
  hours: { minutes: 60, hours: 1, days: 1 / 24 },
  days: { minutes: 1440, hours: 24, days: 1 },
  business_minutes: { business_hours: 1 / 60 },
  business_hours: { business_minutes: 60 },
  business_days: { business_days: 1 },
};

function convert(value: number, from: Unit, to: Unit): number {
  const rate = UNITS[from][to];

  return Number((value * rate).toFixed(3));
}

function isValidForRoundUp(value: number, divisor: number): boolean {
  const remains = value / divisor;

  const decimalPart = remains.toString().split('.')[1];
  return decimalPart ? decimalPart.length < 2 : true;
}
