import { useCallback, useEffect, useRef } from 'react';

import type { StateChangeCallback } from './useStateWithCallback';

import { useDebounce } from '@react-hook/debounce';

export default function useDebouncedStateWithCallback<T>(
  initialState: T,
  wait?: number
): [T, (state: T, callback?: StateChangeCallback<T>) => void] {
  const [state, setState] = useDebounce<T>(initialState, wait || 250);
  const callbackRef = useRef<StateChangeCallback<T> | null | undefined>(null);
  const setStateWithCallback = useCallback(
    (state: T, callback?: StateChangeCallback<T>): void => {
      callbackRef.current = callback;
      setState(state);
    },
    [setState]
  );

  useEffect(() => {
    if (!callbackRef.current) return;

    callbackRef.current(state);
    callbackRef.current = null;
  }, [state]);

  return [state, setStateWithCallback];
}
