import { useCallback, useState } from 'react';
import type { FC } from 'react';

import Header from '@/Header';
import { ErrorMessage } from '@/ui/ErrorMessage';

import NewUsers from '../NewUsers';

import { useGetAutotaskUsersQuery } from '@graphql/generated';

import asScreen from '../../../Screen';

interface Props {
  redirectPath?: string | null;
}

const PAGE_SIZE = 10;

const AutotaskInvitation: FC<Props> = ({ redirectPath }) => {
  const [offset, setOffset] = useState(0);
  const [phrase, setPhrase] = useState('');

  const { data, loading, error } = useGetAutotaskUsersQuery({
    variables: { phrase, limit: PAGE_SIZE, offset },
  });
  const page = data?.autotask?.potentialUsers?.page || 1;
  const size = data?.autotask?.potentialUsers?.size || 10;
  const total = data?.autotask?.potentialUsers?.total || 0;
  const users = (data?.autotask?.potentialUsers?.nodes || []).map(user => ({
    id: user.id?.toString() || '',
    email: user.email,
    name: user.name,
    status: user.status as 'available' | 'connected' | 'exist' | 'invited',
  }));

  const handlePageChange = useCallback((newPage: number): void => {
    setOffset((newPage - 1) * PAGE_SIZE);
  }, []);

  const handleTermChange = useCallback((term: string) => {
    setOffset(0);
    setPhrase(term);
  }, []);

  if (error) {
    return <ErrorMessage>Error loading users from Autotask.</ErrorMessage>;
  }

  return (
    <>
      <Header title="Invite users from Autotask." />
      <NewUsers
        psaName="Autotask"
        currentPage={page}
        count={size}
        loading={loading}
        redirectPath={redirectPath}
        totalPages={total}
        users={users}
        onPageChange={handlePageChange}
        onTermChange={handleTermChange}
      />
    </>
  );
};

export default asScreen(AutotaskInvitation);
