import { useCallback, useState } from 'react';
import type { FC } from 'react';

import Header from '@/Header';
import { ErrorMessage } from '@/ui/ErrorMessage';

import NewUsers from '../NewUsers';

import { useGetOffice365UsersQuery } from '@graphql/generated';
import { NewUser } from '@models/User';

import asScreen from '../../../Screen';

const PAGE_SIZE = 10;

interface Props {
  redirectPath?: string | null;
}

const Office365CalendarInvitation: FC<Props> = ({ redirectPath }) => {
  const [phrase, setPhrase] = useState('');
  const [offset, setOffset] = useState(0);

  const { data, loading, error } = useGetOffice365UsersQuery({
    variables: { phrase, limit: PAGE_SIZE, offset },
  });
  const page = data?.office365?.potentialUsers?.page || 1;
  const size = data?.office365?.potentialUsers?.size || 10;
  const total = data?.office365?.potentialUsers?.total || 0;
  const users = (data?.office365?.potentialUsers?.nodes || []).map(
    user =>
      ({
        id: user.id?.toString() || '',
        email: user.email,
        name: user.name,
        status: user.status,
      }) as NewUser
  );

  const handlePageChange = useCallback((newPage: number): void => {
    setOffset((newPage - 1) * PAGE_SIZE);
  }, []);

  const handleTermChange = useCallback((term: string) => {
    setPhrase(term);
    setOffset(0);
  }, []);

  if (error) {
    return <ErrorMessage>Error loading users from Office 365.</ErrorMessage>;
  }

  return (
    <>
      <Header title="Invite users from Office 365 Calendar." />
      <NewUsers
        psaName="Office 365"
        currentPage={page}
        count={size}
        loading={loading}
        redirectPath={redirectPath}
        totalPages={total}
        users={users}
        onPageChange={handlePageChange}
        onTermChange={handleTermChange}
      />
    </>
  );
};

export default asScreen(Office365CalendarInvitation);
