import { createContext } from 'react';

import type Plan from '@models/Plan';

export const NULL_PLAN: Plan = {
  id: 'free-usd-apr-2020',
  name: '(No Plan)',
  allowsCloseAppointmentsForClosedTickets: false,
  allowsContactUpdating: false,
  allowsCustomAvailability: false,
  allowsCustomEmails: false,
  allowsCustomerSmtpServer: false,
  allowsDispatchHq: false,
  allowsGuestInvitations: false,
  allowsOffice365Calendars: false,
  allowsOnlineMeetings: false,
  allowsPanelScheduling: false,
  allowsRescheduling: false,
  allowsSchedulingLimits: false,
  allowsShareableUrls: false,
  allowsTeamScheduling: false,
  allowsUrlBasedScheduling: false,
  allowsWorkflowEdits: false,
  maximumAppointmentTypes: -1,
  maximumUsers: -1,
  maxResources: 0,
};

export default createContext<Plan>(NULL_PLAN);
