import type { FC } from 'react';
import { useState } from 'react';

import Role from './Role';
import Status from './Status';
import ConfigLink from './ConfigLink';
import Availability from './Availability';
import TimeZone from './TimeZone';

import type { User } from '@graphql/generated';
import type { AvailabilityBlock } from '@models/Availability';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  availabilityAllowed: boolean;
  index: number;
  showAvailability: boolean;
  user: User;
  deactivateUser: () => Promise<void>;
  activateUser: () => Promise<void>;
}

const UserListItem: FC<Props> = ({
  availabilityAllowed,
  index,
  showAvailability,
  user,
  deactivateUser,
  activateUser,
}) => {
  const [loading, setLoading] = useState(false);

  const handleToggle = (event: React.ChangeEvent): void => {
    event.preventDefault();
    setLoading(true);

    if (user.schedulable) {
      deactivateUser().finally(() => setLoading(false));
    } else {
      activateUser().finally(() => setLoading(false));
    }
  };

  return (
    <div className="card">
      <div className="card-body d-flex py-2">
        <div className="flex-grow-1" style={{ width: '40%' }}>
          <div className="form-check form-switch">
            <input
              id={`switch-${index}`}
              className="form-check-input"
              type="checkbox"
              checked={user.schedulable}
              disabled={!user.availability}
              onChange={handleToggle}
            />
            <label className="form-check-label" htmlFor={`switch-${index}`}>
              <div className="fw-bold">
                {user.name}
                &nbsp;
                <Role role={user.role} />
                &nbsp;
                <Status schedulable={user.schedulable} loading={loading} />
              </div>
              <div className="small">{user.email}</div>
            </label>
          </div>
        </div>
        {user.availability && showAvailability && (
          <div style={{ width: '50%' }}>
            <Availability
              availabilityAllowed={availabilityAllowed}
              availabilityBlocks={user.availability.availabilityBlocks as AvailabilityBlock[]}
              usesCustomAvailability={user.availability.usesCustomAvailability}
            />
            <br />
            <TimeZone
              usesCustomTimezone={user.availability.usesCustomTimezone}
              customTimezone={user.availability.customTimezone}
            />
          </div>
        )}
        <div className="text-end" style={{ width: '10%' }}>
          {user.availability ? (
            <ConfigLink userId={user.id} />
          ) : (
            <div>
              <FontAwesomeIcon spin icon="circle-notch" />
              &nbsp;&nbsp; Processing
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserListItem;
