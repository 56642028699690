import type { FC } from 'react';

import classNames from 'classnames';

import Badge from '@ui/Badge';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import Select from '@shared/ui/Select';
import type { NewUser } from '@models/User';

interface Props {
  users: NewUser[];
  disabledUsers?: NewUser[];
  onAdd?: (user: NewUser) => void;
  onRemove?: (user: NewUser) => void;
  onChange?: (user: NewUser) => void;
}

const NewUsersList: FC<Props> = ({ users, disabledUsers, onAdd, onChange, onRemove }) => {
  const disabledUserIds = (disabledUsers || []).map(u => u.id);

  const handleRoleChange = (user: NewUser, event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (typeof onChange !== 'undefined') onChange({ ...user, role: event.target.value });
  };

  const handleUserAdd = (user: NewUser, _: React.MouseEvent<HTMLButtonElement>): void => {
    const invitee = { role: 'user', ...user };
    if (typeof onAdd !== 'undefined') onAdd(invitee);
  };

  const handleUserRemove = (user: NewUser, _: React.MouseEvent<HTMLButtonElement>): void => {
    if (typeof onRemove !== 'undefined') onRemove(user);
  };

  return (
    <table className="table mb-0">
      <thead>
        <tr>
          <th style={{ width: '35%' }}>Name</th>
          <th style={{ width: '35%' }}>Email</th>
          <th style={{ width: '30%' }} className="text-right">
            Role
          </th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          const disabled = disabledUserIds.indexOf(user.id) >= 0;

          return (
            <tr key={user.id} className="col-sm-6" data-testid={user.email}>
              <td className="align-middle">{user.name}</td>
              <td className="align-middle">{user.email}</td>
              <td className="align-middle text-right">
                <div className="d-flex justify-content-end">
                  {user.status === 'available' && (
                    <>
                      <Select
                        className="form-select-sm me-2"
                        disabled={disabled || !onChange}
                        value={user.role || 'user'}
                        name="new-user-role-select"
                        options={[
                          { name: 'User', value: 'user' },
                          { name: 'Administrator', value: 'administrator' },
                        ]}
                        onChange={handleRoleChange.bind(null, user)}
                      />
                      {onAdd && (
                        <button
                          className={classNames('btn', 'btn-sm', {
                            'btn-primary': !disabled,
                            'btn-secondary': disabled,
                          })}
                          disabled={disabled}
                          data-testid="add-user"
                          onClick={handleUserAdd.bind(null, user)}
                        >
                          <FontAwesomeIcon icon="plus" style={{ color: '#fff' }} />
                        </button>
                      )}
                      {onRemove && (
                        <button
                          className={classNames('btn', 'btn-sm', {
                            'btn-danger': !disabled,
                            'btn-secondary': disabled,
                          })}
                          disabled={disabled}
                          data-testid="remove-user"
                          onClick={handleUserRemove.bind(null, user)}
                        >
                          <FontAwesomeIcon icon="times" style={{ color: '#fff' }} />
                        </button>
                      )}
                    </>
                  )}
                  {user.status === 'connected' && <Badge color="grey">User already has a TimeZest account</Badge>}
                  {user.status === 'exist' && <Badge color="yellow">User&apos;s email is already taken</Badge>}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default NewUsersList;
